"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockContentModel = void 0;
exports.BlockContentModel = {
    html: '',
    string: null,
    list: null,
    settings: { bordered: true },
    links: null,
};
// TODO: check constructor usage
var BlockModel = /** @class */ (function () {
    // Аналогичен по смыслу полю libraryTemplate_id
    function BlockModel(_a) {
        var id = _a.id, type = _a.type, order = _a.order, content = _a.content, _b = _a.sectionId, sectionId = _b === void 0 ? null : _b, _c = _a.name, name = _c === void 0 ? '' : _c, _d = _a.group_id, group_id = _d === void 0 ? null : _d, _e = _a.templateElement_id, templateElement_id = _e === void 0 ? null : _e, _f = _a.autonumeric, autonumeric = _f === void 0 ? true : _f, _g = _a.libraryBlock, libraryBlock = _g === void 0 ? null : _g, _h = _a.libraryTemplate_id, libraryTemplate_id = _h === void 0 ? null : _h, _j = _a.isLib, isLib = _j === void 0 ? false : _j, _k = _a.action, action = _k === void 0 ? '' : _k, _l = _a.canBeEdited, canBeEdited = _l === void 0 ? false : _l, _m = _a.numeration_depth, numeration_depth = _m === void 0 ? 0 : _m, _o = _a.external_params, external_params = _o === void 0 ? null : _o, _p = _a.isContentEditable, isContentEditable = _p === void 0 ? true : _p, _q = _a.emptyValue, emptyValue = _q === void 0 ? false : _q, _r = _a.isReceived, isReceived = _r === void 0 ? false : _r, _s = _a.isRequired, isRequired = _s === void 0 ? true : _s, _t = _a.placeholdersIds, placeholdersIds = _t === void 0 ? null : _t, _u = _a.libraryElement_id, libraryElement_id = _u === void 0 ? null : _u, _v = _a.template_id, template_id = _v === void 0 ? null : _v, sectionVariant = _a.sectionVariant, subType = _a.subType;
        this.id = id;
        this.type = type;
        this.order = order;
        this.content = content;
        this.sectionId = sectionId;
        this.name = name;
        this.groupId = group_id;
        this.templateElement_id = templateElement_id;
        this.autonumeric = autonumeric;
        this.libraryBlock = libraryBlock;
        this.libraryTemplate_id = libraryTemplate_id;
        this.isLib = isLib;
        this.action = action;
        this.canBeEdited = canBeEdited;
        this.numeration_depth = numeration_depth;
        this.external_params = external_params;
        this.isContentEditable = isContentEditable;
        this.emptyValue = emptyValue;
        this.isReceived = isReceived;
        this.isRequired = isRequired;
        this.placeholdersIds = placeholdersIds;
        this.libraryElement_id = libraryElement_id;
        this.template_id = template_id;
        this.sectionVariant = sectionVariant;
        this.subType = subType;
    }
    return BlockModel;
}());
exports.default = BlockModel;
