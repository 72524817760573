"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractTlSections = exports.extractSections = void 0;
var BlockModel_1 = __importStar(require("../components/TemplateRender/Blocks/BlockModel"));
var BlockTypes_1 = require("../components/TemplateRender/BlockTypes");
var checkJsonString_1 = require("./checkJsonString");
function extractSections(elementsList, isContentEditable) {
    var _a;
    if (isContentEditable === void 0) { isContentEditable = false; }
    var sections = [];
    var sectionsList = (_a = elementsList === null || elementsList === void 0 ? void 0 : elementsList.templateElements) === null || _a === void 0 ? void 0 : _a.filter(function (b) { return b.parentElement_id && typeof b.elementType !== BlockTypes_1.blockTypes.section.type; });
    sections = sectionsList === null || sectionsList === void 0 ? void 0 : sectionsList.map(function (curBlock) {
        var block = __assign({}, curBlock);
        var blockModel = new BlockModel_1.default({
            id: block.id,
            type: block.elementType,
            order: "".concat(block.parentElement_id, "_").concat(block.groupElementNumber),
            content: __assign(__assign({}, BlockModel_1.BlockContentModel), (block.content !== null && block.content !== '' ? JSON.parse(block.content) : BlockModel_1.BlockContentModel)),
            sectionId: block.parentElement_id,
            name: block.name,
            group_id: block.group_id,
        });
        blockModel.groupName = block.group_name;
        blockModel.autonumeric = block.autonumeric;
        blockModel.canBeEdited = block.canBeEdited;
        blockModel.numeration_depth = block.numeration_depth || 0;
        blockModel.libraryTemplate_id = block.fakeTemplate_id;
        blockModel.libraryElement_id = block.fakeElement_id;
        blockModel.isLib = block.isLib;
        blockModel.subType = block.subType;
        blockModel.isReceived = block.isReceived;
        blockModel.isRequired = block.isRequired;
        blockModel.emptyValue = block.emptyValue;
        // для пустого варианта в секции проверяется и block.autonumeric
        // т.к. его нельзя редактировать никогда, в отличии от остальных вариантов
        // blockModel.isContentEditable = block.autonumeric && isContentEditable;
        blockModel.isContentEditable = isContentEditable && !blockModel.emptyValue;
        blockModel.external_params = (0, checkJsonString_1.checkJsonString)(block.external_params)
            ? JSON.parse(block.external_params)
            : null;
        return blockModel;
    });
    return sections;
}
exports.extractSections = extractSections;
function extractTlSections(extractsRepeatedSections, isContentEditable) {
    if (isContentEditable === void 0) { isContentEditable = false; }
    var templateElements = extractsRepeatedSections === null || extractsRepeatedSections === void 0 ? void 0 : extractsRepeatedSections.map(function (item) { return item.templateElement; });
    var sections = [];
    // фильтр сущностей по отсутствию в них секций
    var sectionsList = templateElements === null || templateElements === void 0 ? void 0 : templateElements.filter(function (b) { return b.parentElement_id && b.elementType !== 6; });
    sections = sectionsList === null || sectionsList === void 0 ? void 0 : sectionsList.map(function (curBlock) {
        var block = __assign({}, curBlock);
        var blockModel = new BlockModel_1.default({
            id: block.id,
            type: block.elementType,
            order: "".concat(block.parentElement_id, "_").concat(block.groupElementNumber),
            content: __assign(__assign({}, BlockModel_1.BlockContentModel), (block.content !== null && block.content !== '' ? JSON.parse(block.content) : BlockModel_1.BlockContentModel)),
            sectionId: block.parentElement_id,
            name: block.name,
            group_id: block.group_id,
        });
        blockModel.groupName = block.group_name;
        blockModel.autonumeric = false;
        blockModel.canBeEdited = false;
        blockModel.numeration_depth = block.numeration_depth || 0;
        blockModel.libraryTemplate_id = block.fakeTemplate_id;
        blockModel.libraryElement_id = block.fakeElement_id;
        blockModel.isLib = block.isLib;
        blockModel.subType = 'templatedListBlock';
        blockModel.isReceived = block.isReceived;
        blockModel.isRequired = block.isRequired;
        blockModel.emptyValue = block.emptyValue;
        // для пустого варианта в секции проверяется и block.autonumeric
        // т.к. его нельзя редактировать никогда, в отличии от остальных вариантов
        // blockModel.isContentEditable = block.autonumeric && isContentEditable;
        blockModel.isContentEditable = false;
        blockModel.external_params = (0, checkJsonString_1.checkJsonString)(block.external_params)
            ? JSON.parse(block.external_params)
            : null;
        return blockModel;
    });
    return sections;
}
exports.extractTlSections = extractTlSections;
